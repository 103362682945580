import { useTheme, Box, Container, Paper, Typography, Stack } from "@mui/material";
import * as React from "react";
import { Trans, useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { scroller } from 'react-scroll'


import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import { SubTitle } from "../../components/Common";

export function Tab1of4() {
    const history = useHistory();
    const theme = useTheme();
    const { t } = useTranslation();


    return (<>
        <SubTitle title="訂購辦法" />
        <br />
        <Typography variant='body1' >
            ※個人會員年費500元，入會費500元，會員將獲得活動優惠、學務第一手資訊，並獲贈《學生事務與輔導》季刊(非個人會員每本250元)。
        </Typography>
        <br />
        <Typography variant='body1' >
            ※投稿本刊物，稿件一經刊登，將贈送當期《學生事務與輔導》1本。
        </Typography>
    </>)

}
