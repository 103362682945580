import * as React from 'react';
import { Box, Button, Typography, styled, Toolbar, alpha, BoxProps, List, ListItemButton, ListItemIcon, ListItemText, Collapse } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ExpandLess, ExpandMore, Logout, StarBorder } from '@mui/icons-material';
import { ManageSideBarItem } from './ManageBoardIndex';
import { useDispatchWithType, useScrollTrigger, useUser } from '../store';
import { useLogoutMutation } from '../components/services/login';
import { deleteAllCookie } from '../cookie-utils';
import { logoutUser, setScrollTrigger } from '../store/rootReducer';
import { useHistory } from 'react-router-dom';

const Gap: (props: BoxProps) => JSX.Element = styled(Box)(({ theme }) => ({
	height: 0,
	"&.overflow": {
		content: "' '",
		height: "4px",
		background: `radial-gradient(${theme.palette.grey[400]} 0%, ${theme.palette.grey[300]} 20%, ${theme.palette.background.default} 80%)`,
		transition: theme.transitions.create(['background']),
	}
}));


export default function ManageSideBar(props: ManageSideBarProps) {
	const { allowSideBarList, handleChange, currentPage, defaultExpanded } = props;
	const { t } = useTranslation();

	//登出
	const dispatch = useDispatchWithType();
	const [logout] = useLogoutMutation();
	const handleClickLogout = () => {
		logout().unwrap().then(result => {
			if (result.isSuccess) {
				dispatch(logoutUser());
				deleteAllCookie();
				window.location.href = '/Login?redirect=/Manage';
			}
		}).catch((error) => {
			console.log("Logout Failed!");
			console.log(error);
		});
	};
	const user = useUser();
	React.useEffect(() => {
		console.log(user)
	}, [user])
	//紀錄目前節點
	const [currentNode, setCurrentNode] = React.useState<ManageSideBarItem>();
	const [selected, setSelected] = React.useState<string[]>(defaultExpanded);
	function find(nodes: ManageSideBarItem[], criteriaFn) {
		let path = [];
		for (let i = 0; i < nodes.length; i++) {
			if (nodes[i].pageList?.length > 0) {
				path = find(nodes[i].pageList, criteriaFn);
				if (path.length > 0) {
					return [nodes[i].name, ...path];
				}
			}
			else if (criteriaFn(nodes[i])) {
				return [nodes[i].name];
			}
		}
		return path;
	}
	async function handleOnChange(node: ManageSideBarItem) {
		let element = find(allowSideBarList, item => item.name === node.name);
		setSelected(element);
		setCurrentNode(node);
		return handleChange(node.value, node.query);
	};


	return (
		<Box display="flex" flex="1" flexDirection="column" overflow="hidden" paddingTop={3}>
			<List>
				{allowSideBarList.map((data) => (<ManageButton key={data.name} data={data} handleChange={handleChange} />))}
			</List>
			<Gap />
			<Box display="flex" flex="1 0 0" />

			<Box display="flex" alignItems="center" pl={1.5} py={1} color="primary.main"
				sx={{
					width: "248px",
					borderRadius: "0px 50px 50px 0px",
					cursor: "pointer",
					"&:hover": { background: (theme) => theme.palette.action.selected, fontWeight: 'bold', }
				}}
				onClick={handleClickLogout}
			>
				<Box mr={1}><Logout /></Box>
				<Typography variant="subtitle1" color="primary.main" fontWeight="inherit">
					{t('manage.title.logout')}
				</Typography>
			</Box>
			<Toolbar variant="dense" />
		</Box >
	)
}
interface ManageSideBarProps {
	allowSideBarList: ManageSideBarItem[];
	handleChange?: (newValue: string, query?: string) => Promise<void>;
	currentPage: string;
	defaultExpanded?: string[];
}

interface ManageButtonProps {
	data: ManageSideBarItem;
	handleChange?: (newValue: string, query?: string) => Promise<void>;
}

function ManageButton(props: ManageButtonProps) {
	const { data, handleChange } = props;
	const [open, setOpen] = React.useState(false);
	const history = useHistory();

	const handleClick = () => {
		setOpen(!open);
	};

	const handlePageClick = (page: ManageSideBarItem) => {
		handleChange(page.value, page.query);
	}


	if (data.pageList?.length > 0)
		return (<>
			<ListItemButton onClick={handleClick}>
				<ListItemText primary={data.name} />
				{open ? <ExpandLess /> : <ExpandMore />}
			</ListItemButton>
			<Collapse in={open} timeout="auto" unmountOnExit>
				<List component="div" disablePadding>
					{data.pageList?.map((page) => (
						<ListItemButton key={page.name} sx={{ pl: 4 }} onClick={() => handlePageClick(page)}>
							<ListItemText primary={page.name} />
						</ListItemButton>
					))}
				</List>
			</Collapse>
		</>)
	else
		return (
			<ListItemButton onClick={() => handlePageClick(data)}>
				<ListItemText primary={data.name} />
			</ListItemButton>
		)

}