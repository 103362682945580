import { useTheme, Box, Container, Paper, Typography, Stack, Table, TableBody, TableRow, TableCell, TableHead, Link } from "@mui/material";
import * as React from "react";
import { Trans, useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { scroller } from 'react-scroll'


import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import { SubTitle } from "components/Common";
import { GroupList } from "components/GroupList";

export function Tab1of3() {
    const history = useHistory();
    const theme = useTheme();
    const { t } = useTranslation();


    return (<>
        <SubTitle title="徵稿辦法" />
        <br />
        <Typography variant='h5' >
            《學生事務與輔導》徵稿辦法
        </Typography>
        <br />
        <Typography variant='subtitle1' >
            一、發行宗旨
        </Typography>
        <br />
        <Typography variant='body1' >
            &emsp;&emsp;《學生事務與輔導》係中華學生事務學會主辦之學生事務與輔導專業性刊物，旨在提供中等學校及大專校院有關學生事務與輔導之學術發展與實務分享。   <br />
        </Typography>
        <br />
        <Typography variant='subtitle1' >
            二、徵稿範圍
        </Typography>
        <br />
        <Table sx={{ '& th,& td': { padding: '10px 5px' } }}>
            <TableHead>
                <TableRow>
                    <TableCell>稿件類型</TableCell>
                    <TableCell>說明</TableCell>
                    <TableCell>字數</TableCell>
                    <TableCell>審查方式</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                <TableRow>
                    <TableCell>焦點話題</TableCell>
                    <TableCell>與本刊當期焦點話題主題相關之論述文章</TableCell>
                    <TableCell>最高4,000字</TableCell>
                    <TableCell>單審</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>理論研究</TableCell>
                    <TableCell>中等教育或大專校院學生事務與輔導之實證研究、專業理論與實務論述、相關專書之評論。</TableCell>
                    <TableCell>最高15,000字</TableCell>
                    <TableCell>雙審</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>特教園地</TableCell>
                    <TableCell>中等教育或大專校院特殊教育之相關論述或案例介紹。</TableCell>
                    <TableCell>最高4,000字</TableCell>
                    <TableCell>單審</TableCell>
                </TableRow>
            </TableBody>
        </Table>
        <br />
        <Typography variant='subtitle1' >
            三、徵稿對象
        </Typography>
        <br />
        <Typography variant='body1' >
            &emsp;&emsp;關心學生事務發展者，均歡迎投稿。 </Typography>
        <br />
        <Typography variant='subtitle1' >
            四、徵稿規則
        </Typography>
        <br />
        <Typography variant='body1' >
            &emsp;&emsp;來稿之參考文獻及其引用方式請依APA格式撰寫（詳見「學生事務與輔導」撰稿體例）；附圖請逕採電腦檔案製作並存檔，或用白紙墨繪，務求工整清晰。
        </Typography>
        <br />
        <dl style={{ marginLeft: '2rem' }}>
            <dd>（一）焦點話題：內容應包括前言（例如動機、目的）、本文、結論、參考文獻。各期主題詳參公告之<Link href='http://www.ipress.tw/J0076' target='_blank'>「最新消息」</Link>。
                <Table sx={{ '& th,& td': { padding: '10px 5px' } }}>
                    <TableHead>
                        <TableRow>
                            <TableCell>卷期</TableCell>
                            <TableCell>主題</TableCell>
                            <TableCell>截稿日期</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell>113年3月出版59卷第4期</TableCell>
                            <TableCell>校園安全</TableCell>
                            <TableCell>112年11月30日</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>113年6月出版60卷第1期</TableCell>
                            <TableCell>自主學習</TableCell>
                            <TableCell>113年2月28日</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>113年9月出版60卷第2期</TableCell>
                            <TableCell>校園霸凌</TableCell>
                            <TableCell>113年5月31日</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>113年12月出版60卷第3期</TableCell>
                            <TableCell>品德教育</TableCell>
                            <TableCell>113年8月31日</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
                <br />
            </dd>
            <dd>（二）理論研究:<br />
                <ul style={{ listStyleType: 'decimal' }}>
                    <li>請另附中文摘要500 字、英文摘要250 字以內（均含中英文標題及中英文關鍵詞）。論文正文及中英文摘要中請勿出現任何姓名或服務單位等類似之個人資料。</li>
                    <li>論文架構根據美國心理學會（APA）撰文架構為原則。</li>
                </ul>
            </dd>
            <dd>（三）特教園地：內容應包括緒論（例如動機、目的）、本文、結論、參考文獻。</dd>
        </dl>
        <br />
        <Typography variant='subtitle1' >
            五、審稿方式
        </Typography>
        <br />
        <dl style={{ marginLeft: '0.5rem' }}>
            <dd>（一）「理論研究」部分
                <br />
                <br />
                <Typography variant='body1' >
                    &emsp;&emsp;本刊「理論研究」部分之審稿制度，包括初審、外審、編輯審等三個階段。
                </Typography>
                <ul style={{ listStyleType: 'decimal', marginLeft: '1rem' }}>
                    <li>
                        第一階段：由本刊進行初審<br />
                        <Box marginLeft='1rem'>
                            <dl>
                                <dd>(1)本刊就來稿作初步形式篩選，確認投稿者及稿件基本資料，投稿文章形式要件是否符合徵稿辦法所公告之要求。</dd>
                                <dd>(2)不符合本刊性質、形式要件、嚴謹程度者，由主編確定後，逕予退稿。</dd>
                            </dl>
                        </Box>
                        <br />
                    </li>
                    <li>
                        第二階段：本刊就來稿確認體例是否符合論文寫作格式，文章內容是否具有刊登價值。將初審合格之稿件，以匿名方式送相關領域之專業學者進行外部審查。<br />
                        <Box marginLeft='0.5rem'>
                            <dl>
                                <dd>(1)外審意見分為四類：a.接受 b.修改後接受 c.修改後重審 d.拒絕。
                                    <Table sx={{ '& th,& td': { padding: '10px 5px' } }}>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell colSpan={2} rowSpan={2}>處理方式</TableCell>
                                                <TableCell colSpan={4}>第二位評審意見</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>接受</TableCell>
                                                <TableCell>
                                                    修改後接受</TableCell>
                                                <TableCell>修改後重審</TableCell>
                                                <TableCell>拒絕</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell rowSpan={4}>第一位評審意見</TableCell>
                                                <TableCell> 接受</TableCell>
                                                <TableCell>刊登</TableCell>
                                                <TableCell>寄回修改</TableCell>
                                                <TableCell>寄回修改</TableCell>
                                                <TableCell>第三位評審</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>修改後接受</TableCell>
                                                <TableCell>寄回修改</TableCell>
                                                <TableCell>寄回修改</TableCell>
                                                <TableCell>寄回修改</TableCell>
                                                <TableCell>第三位評審</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>修改後重審 </TableCell>
                                                <TableCell>寄回修改</TableCell>
                                                <TableCell>寄回修改</TableCell>
                                                <TableCell>寄回修改</TableCell>
                                                <TableCell>退稿</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>拒絕 </TableCell>
                                                <TableCell>第三位評審</TableCell>
                                                <TableCell>第三位評審</TableCell>
                                                <TableCell>退稿</TableCell>
                                                <TableCell>退稿</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </dd>
                                <dd>(2)若兩位外審評審者對於採用與否意見有出入時，由本刊另聘第三位評審人匿名審查，由編輯委員會根據三位審查者的意見，決定是否採用。</dd>
                                <dd>(3)凡審稿者建議「修改後重審」之文稿，作者需於要求期限內修改完畢，將修改後之文章，連同「稿件修改及答覆說明書」上傳投稿系統，由本刊提交原審查者進行複審。</dd>
                                <dd>(4)審稿意見為「接受」或「修改後接受」者，原則上考慮刊登；審稿意見為「拒絕」者，不予刊登。</dd>
                                <dd>(5)外審稿件之審稿意見為「接受」、「修改後接受」或「修改後重審」複審通過者，其刊登與否，仍需由主編確認其文章品質後，提交編輯委員會，根據評審意見、編輯特色等因素作成決定。</dd>
                            </dl>
                        </Box>
                        <br />
                    </li>
                    <li>
                        第三階段：每期出刊前，由本刊編輯委員會開會，針對通過外審之候用稿件進行最後之決定審。
                        <Box marginLeft='1rem'>
                            <dl>
                                <dd>(1)凡經外審建議刊登之文章，均需由主編確認其文章品質後，提交編輯委員會審查。審查流程以匿名處理為原則，即受審作者的身份均不顯露。</dd>
                                <dd>(2)如未能依照審稿意見及本刊格式要求修改之文章，經編輯委員會之決議，得暫緩或撤銷刊登。</dd>
                                <dd>(3)修正之稿件經編輯委員會審查合宜者，需於要求期限內寄回修正稿件電子檔（Microsoft Word），以利出版，否則恕難如期刊登。</dd>
                            </dl>
                        </Box>
                        <br />
                    </li>
                </ul>
            </dd>
            <dd>（二）本刊「焦點話題」與「特教園地」部分
                <br />
                <br />
                <Typography variant='body1' >
                    &emsp;&emsp;本刊就來稿確認體例是否符合本刊要求格式，文章內容是否符合當期主題並具有刊登價值。將初審合格之稿件，以匿名方式送相關領域之專業學者進行審查。惟本刊篇幅有限，「特教園地」若來稿符合本刊價值且通過審查，本刊對於刊登的卷期具有決定權。
                </Typography>
                <Typography variant='body1' >
                    &emsp;&emsp;由於「焦點話題」必須符合當期主題，來稿會先由編輯委員會審查主題是否符合，如果符合才會進入審查程序，如主題不符則會逕予退稿。
                </Typography>
            </dd>
        </dl>
        <Typography variant='subtitle1' >
            六、出版方式
        </Typography>
        <br />
        <dl style={{ marginLeft: '0.5rem' }}>
            <dd>(一)本刊一年出版四期，分別於三月、六月、九月、十二月出刊。</dd>
            <dd>(二)本刊編輯小組視稿件數量、投稿順序等因素，決定通過審查稿件之刊登卷期。</dd>
            <dd>(三)因編輯需要，編輯小組保有稿件內容部分刪修權，完全不願刪改者，請先註明。</dd>
            <dd>(四)稿件一經刊登，將贈送當期《學生事務與輔導》2本。</dd>
        </dl>
        <br />
        <Typography variant='subtitle1' >
            七、投稿注意事項
        </Typography>
        <br />
        <dl style={{ marginLeft: '0.5rem' }}>
            <dd>(一)請參考APA（第六版）格式和本刊撰寫格式要點撰寫稿件（請至投審稿系統或學會網頁下載）。稿件若格式不符、字數逾規定等均將退回，待修正後，再行送審。</dd>
            <dd>(二)投稿「理論研究」文章須上傳英文名稱與摘要，其他稿件類型則不須上傳英文名稱與摘要。</dd>
            <dd>(三)請勿一稿兩投（此指投送其他刊物正審查中，或研討會發表論文將編輯成專  書）。如有抄襲、侵犯他人著作權和涉及言論責任之糾紛，悉由作者自負。</dd>
            <dd>(四)投稿時，請逕至以下網址（<Link href='http://aspers.airiti.com/SAGC/' target='_blank'>http://aspers.airiti.com/SAGC/</Link>）註冊登入，並使用線上投稿系統。此外，為符合匿名審查原則作業，請勿於稿件中標出作者及服務單位；若正文、註解或附錄有明顯出現與作者身份相關之任何資料（引用自己的資料，不在此限）；或稿件字數、內容不符合本期刊要求者，本期刊編輯小組將逕予「形式審查不通過」退稿。</dd>
            <dd>請以線上投稿系統進行投稿與修正。理論研究文章如審查通過，依審查意見修改後之定稿則務必分別於中、英文標題下補寫中、英文作者姓名及服務單位，並將電子檔（word檔）登入系統。</dd>
            <dd>(六)投稿文章如收到「修改後重審」的意見，請於期限內修改回覆「稿件修改與答覆說明書」（附件一）與修改後文章，經兩次提醒，如無提出可接受延期的理由，將逕予退稿，並於兩期內不接受作者投稿。</dd>
        </dl>
        <br />
        <Typography variant='subtitle1' >
            八、本辦法經本刊編輯委員會會議通過後實施，修正時亦同。
        </Typography>
        <br />
        <br />
    </>)

}
