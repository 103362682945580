import { useTheme, Box, Container, Paper, Typography, Stack, Accordion, AccordionSummary, AccordionDetails, TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from "@mui/material";
import * as React from "react";
import { Trans, useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { scroller } from 'react-scroll'


import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import { SubTitle } from "../../components/Common";
import { GroupList } from "../../components/GroupList";

export function Tab3() {
    const history = useHistory();
    const theme = useTheme();
    const { t } = useTranslation();
    const contents = [
        {
            title: "第45屆理事名單",
            content: GroupList([
                {
                    gruop: "名譽理事長",
                    list: [
                        { name: "柯志堂", info: "德明財經科技大學 學務長" },
                    ]
                },
                {
                    gruop: "理事長",
                    list: [
                        { name: "林至善", info: "玄奘大學 前學務長/現任宗教與文化學系副教授" },
                    ]
                },
                {
                    gruop: "副理事長",
                    list: [
                        { name: "劉若蘭", info: "國立臺灣師範大學 全人教育中心主任/公民教育與活動領導系教授" },
                        { name: "蔡文彬", info: "台鋼科技大學 學務長" },
                    ]
                },
                {
                    gruop: "常務理事",
                    list: [
                        { name: "林沛練", info: "國立中央大學 學務長" },
                        { name: "顏世慧", info: "樹德科技大學 學務長" },
                        { name: "陳隆昇", info: "朝陽科技大學 助理副校長兼教務長" },
                        { name: "黃明雪", info: "德明財經科技大學 課外活動組長" },
                    ]
                },
                {
                    gruop: "理事",
                    list: [
                        { name: "鄭學淵", info: "國立臺灣海洋大學 學務長" },
                        { name: "洪良宜", info: "國立成功大學 學務長" },
                        { name: "劉維群", info: "國立高雄餐旅大學 主任秘書" },
                        { name: "王英洲", info: "輔仁大學 學術副校長" },
                        { name: "鄭丞良", info: "輔仁大學 學務長" },
                        { name: "顏政通", info: "馬偕醫護管理專科學校 學務長" },
                        { name: "陳建森", info: "馬偕醫護管理專科學校 前學務長/現任學務處體育運動組長" },
                        { name: "方元沂", info: "中國文化大學 前學務長/現任教務長" },
                        { name: "陳新霖", info: "元智大學 課外活動組長及通識中心兼任助理教授" },
                        { name: "林株啓", info: "建國科技大學 課外活動組長" },
                        { name: "楊益強", info: "臺北市立大安高級工業職業學校 校長" },
                        { name: "蔡昕璋", info: "國立臺灣師範大學 全人教育中心管理師" },
                        { name: "吳振邦", info: "國立臺灣師範大學 全人教育中心博士後研究員" },
                        { name: "王柏智", info: "健行科技大學課外活動組長" },
                    ]
                },])
        },
        {
            title: "第45屆監事名單",
            content: GroupList([

                {
                    gruop: "常務監事",
                    list: [
                        { name: "梁朝雲", info: "元智大學 前學務長/現任國立臺灣大學生物產業傳播暨發展學系 教授" },
                    ]
                },
                {
                    gruop: "監事",
                    list: [
                        { name: "何進財", info: "前教育部訓育委員會常委" },
                        { name: "楊昌裕", info: "教育部 前國教司長/遠東科技大學 前副校長" },
                        { name: "柳金財", info: "佛光大學 前學務長/現任公共事務學系副教授" },
                        { name: "張麗君", info: "明志科技大學 前學務長/現任材料工程系 教授" },
                        { name: "朱朝煌", info: "國立成功大學 兼任助理教授退休" },
                        { name: "李立旻", info: "國立臺灣師範大學 專責導師" },

                    ]
                },])
        },
        {
            title: "第44屆理事名單",
            content: GroupList([
                {
                    gruop: "名譽理事長",
                    list: [
                        { name: "柯志堂", info: "德明財經科技大學 學務長" },
                    ]
                },
                {
                    gruop: "理事長",
                    list: [
                        { name: "林至善", info: "玄奘大學 前學務長/現任宗教與文化學系副教授" },
                    ]
                },
                {
                    gruop: "副理事長",
                    list: [
                        { name: "劉若蘭", info: "國立臺灣師範大學 全人教育中心主任/公民教育與活動領導系教授" },
                        { name: "蔡文彬", info: "遠東科技大學 學務長" },
                    ]
                },
                {
                    gruop: "常務理事",
                    list: [
                        { name: "劉維群", info: "國立高雄餐旅大學 主任秘書" },
                        { name: "顏世慧", info: "樹德科技大學 學務長" },
                        { name: "王英洲", info: "輔仁大學 前學務長/現任教務長" },
                        { name: "陳建森", info: "馬偕醫護管理專科學校 前學務長/現任體育組長" },
                    ]
                },
                {
                    gruop: "理事",
                    list: [
                        { name: "林麗娟", info: "國立成功大學 學務長" },
                        { name: "林沛練", info: "國立中央大學 學務長" },
                        { name: "鄭學淵", info: "國立臺灣海洋大學 學務長" },
                        { name: "顏政通", info: "馬偕醫護管理專科學校 學務長" },
                        { name: "方元沂", info: "中國文化大學 前學務長/現任教務長" },
                        { name: "洪敬富", info: "國立成功大學 前學務長/現任政治學系教授" },
                        { name: "柳金財", info: "佛光大學 前學務長/佛光大學公共事務學系副教授" },
                        { name: "王信雄", info: "亞東科技大學 前學務長/亞東科技大學電機系副教授" },
                        { name: "洪錦珠", info: "華夏科技大學 前學務長/公立高職甄試委員" },
                        { name: "張麗君", info: "明志科技大學 前學務長/現任材料系教授" },
                        { name: "陳新霖", info: "元智大學課外活動組長" },
                        { name: "黃明雪", info: "德明財經科技大學 課外活動指導組長" },
                        { name: "朱朝煌", info: "國立成功大學 兼任助理教授(退休)" },
                        { name: "楊益強", info: "臺北市立大安高級工業職業學校 校長" },
                    ]
                },])
        },
        {
            title: "第44屆監事名單",
            content: GroupList([

                {
                    gruop: "常務監事",
                    list: [
                        { name: "何英奇", info: "國立臺灣師範大學教育心理與輔導學系教授" },
                    ]
                },
                {
                    gruop: "監事",
                    list: [
                        { name: "梁朝雲", info: "元智大學前學務長/國立臺灣大學生物產業傳播暨發展學系教授" },
                        { name: "王佳煌", info: "元智大學社會暨政策科學系教授" },
                        { name: "陳隆昇", info: "朝陽科技大學助理副校長兼教務長" },
                        { name: "何進財", info: "前教育部訓育委員會常委" },
                        { name: "楊昌裕", info: "遠東科技大學前副校長" },
                        { name: "林株啓", info: "建國科技大學課外指導組組長" },

                    ]
                },])
        },
        {
            title: "第43屆理事名單",
            content: GroupList([
                {
                    gruop: "理事長",
                    list: [
                        { name: "柯志堂", info: "德明財經科技大學會計資訊系法學副教授兼學生事務長" },
                    ]
                },
                {
                    gruop: "副理事長",
                    list: [
                        { name: "柳金財", info: "佛光大學學生事務長" },
                        { name: "王英洲", info: "輔仁大學醫學院臨床心理學系教授兼學生事務長" },
                    ]
                },
                {
                    gruop: "常務理事",
                    list: [
                        { name: "洪錦珠", info: "華夏科技大學通識教育中心副教授兼學務長" },
                        { name: "陳昭雄", info: "前弘光科技大學副校長/ 弘光科技大學食品科技系教授" },
                        { name: "王延年", info: "龍華科技大學學務長" },
                        { name: "鄭武德", info: "前明新科技大學學務長/ 現任明新科技大學進修推廣部主任" },
                    ]
                },
                {
                    gruop: "理事",
                    list: [
                        { name: "劉若蘭", info: "國立臺灣師範大學公民活動領導學系副教授" },
                        { name: "田華忠", info: "國立臺灣海洋大學學務長、機械與機電工程學系副教授" },
                        { name: "洪敬富", info: "國立成功大學學務長" },
                        { name: "劉維群", info: "國立高雄餐旅大學通識中心主任" },
                        { name: "王佳煌", info: "元智大學學務長" },
                        { name: "顏世慧", info: "樹德科技大學學生事務長" },
                        { name: "蔡文彬", info: "遠東科技大學學務長" },
                        { name: "顏政通", info: "馬偕醫護管理專科學校助理教授兼學務主任" },
                        { name: "朱朝煌", info: "國立成功大學課指組副組長" },
                        { name: "林至善", info: "玄奘大學通識中心副教授" },
                        { name: "陳新霖", info: "元智大學諮商與就業輔導組社工師、 元智大學兼任助理教授" },
                        { name: "王信雄", info: "前亞東技術學院學務長/ 現任亞東技術學院電機系副教授" },
                        { name: "周雪美", info: "前元培科技大學學生事務長" },
                        { name: "楊益強", info: "臺北市立松山高級工農職業學校校長" },
                    ]
                },])
        },
        {
            title: "第43屆監事名單",
            content: GroupList([

                {
                    gruop: "常務監事",
                    list: [
                        { name: "林詩輝", info: "前國大代表、醒吾科技大學學務長/ 現任醒吾科技大學副教授" },
                    ]
                },
                {
                    gruop: "監事",
                    list: [
                        { name: "楊昌裕", info: "前教育部國教司司長/ 現任遠東科技大學休閒與運動管理系副教授兼副校長" },
                        { name: "柯慧貞", info: "亞洲大學講座教授兼副校長" },
                        { name: "何進財", info: "前教育部訓委會常委" },
                        { name: "梁朝雲", info: "前元智大學學務長/ 現任國立臺灣大學教授" },
                        { name: "何英奇", info: "前文化大學學務長/ 現任文化大學教授兼心輔系主任" },
                        { name: "林賜德", info: "前中原大學學務長/ 現任中原大學教授兼人育學院院長" },
                    ]
                },])
        },
        {
            title: "第42屆理事名單",
            content: GroupList([
                {
                    gruop: "理事長",
                    list: [
                        { name: "柯志堂", info: "德明財經科技大學會計資訊系法學副教授兼學生事務長" },
                    ]
                },
                {
                    gruop: "副理事長",
                    list: [
                        { name: "柳金財", info: "佛光大學學生事務長" },
                        { name: "王英洲", info: "輔仁大學醫學院臨床心理學系教授兼學生事務長" },
                    ]
                },
                {
                    gruop: "常務理事",
                    list: [
                        { name: "洪錦珠", info: "華夏科技大學通識教育中心副教授兼學務長" },
                        { name: "陳昭雄", info: "前弘光科技大學副校長/ 弘光科技大學食品科技系教授" },
                        { name: "王延年", info: "龍華科技大學學務長" },
                        { name: "鄭武德", info: "前明新科技大學學務長/ 現任明新科技大學進修推廣部主任" },
                    ]
                },
                {
                    gruop: "理事",
                    list: [
                        { name: "劉若蘭", info: "國立臺灣師範大學公民活動領導學系副教授" },
                        { name: "田華忠", info: "國立臺灣海洋大學學務長、機械與機電工程學系副教授" },
                        { name: "董旭英", info: "國立成功大學學務長" },
                        { name: "劉維群", info: "國立高雄餐旅大學通識中心主任" },
                        { name: "王佳煌", info: "元智大學學務長" },
                        { name: "顏世慧", info: "樹德科技大學學生事務長" },
                        { name: "蔡文彬", info: "遠東科技大學學務長" },
                        { name: "陳建森", info: "馬偕醫護管理專科學校助理教授兼學務主任" },
                        { name: "朱朝煌", info: "國立成功大學課指組副組長" },
                        { name: "林至善", info: "玄奘大學通識中心副教授" },
                        { name: "陳新霖", info: "元智大學諮商與就業輔導組社工師、 元智大學兼任助理教授" },
                        { name: "王信雄", info: "前亞東技術學院學務長/ 現任亞東技術學院電機系副教授" },
                        { name: "周雪美", info: "前元培科技大學學生事務長" },
                        { name: "楊益強", info: "臺北市立松山高級工農職業學校校長" },
                    ]
                },])
        },
        {
            title: "第42屆監事名單",
            content: GroupList([

                {
                    gruop: "常務監事",
                    list: [
                        { name: "林詩輝", info: "前國大代表、醒吾科技大學學務長/ 現任醒吾科技大學副教授" },
                    ]
                },
                {
                    gruop: "監事",
                    list: [
                        { name: "楊昌裕", info: "前教育部國教司司長/ 現任遠東科技大學休閒與運動管理系副教授兼副校長" },
                        { name: "柯慧貞", info: "亞洲大學講座教授兼副校長" },
                        { name: "何進財", info: "前教育部訓委會常委" },
                        { name: "梁朝雲", info: "前元智大學學務長/ 現任國立臺灣大學教授" },
                        { name: "何英奇", info: "前文化大學學務長/ 現任文化大學教授兼心輔系主任" },
                        { name: "林賜德", info: "前中原大學學務長/ 現任中原大學教授兼人育學院院長" },

                    ]
                },])
        },
        {
            title: "第41屆理事名單",
            content: GroupList([
                {
                    gruop: "榮譽理事長",
                    list: [
                        { name: "周家華", info: "國立臺北科技大學教授、前醒吾、德霖技術學院校長" },
                    ]
                },
                {
                    gruop: "理事長",
                    list: [
                        { name: "張雪梅", info: "國立臺灣師範大學公民教育與活動領導學系教授" },
                    ]
                },
                {
                    gruop: "副理事長",
                    list: [
                        { name: "童中儀", info: "康寧大學副董事長兼企業管理科專任副教授" },
                        { name: "柯志堂", info: "德明財經科技大學會計資訊系法學副教授兼學務長" },
                    ]
                },
                {
                    gruop: "常務理事",
                    list: [
                        { name: "王天楷", info: "國立臺灣海洋大學應用地球科學研究所教授" },
                        { name: "林至善", info: "玄奘大學通識中心專任副教授" },
                        { name: "柳金財", info: "佛光大學學務長" },
                        { name: "楊健貴", info: "銘傳大學前學務長/ 現任資訊工程學系教授" },
                        { name: "劉若蘭", info: "國立臺灣師範大學公民活動領導學系副教授" },
                        { name: "劉維群", info: "國立臺灣海洋大學學務長、機械與機電工程學系副教授" },
                        { name: "鄭武德", info: "前明新科技大學學務長/ 現任明新科技大學進修推廣部主任" },
                    ]
                },
                {
                    gruop: "理事",
                    list: [
                        { name: "林錦川", info: "東吳大學專任教授" },
                        { name: "劉杏元", info: "長庚科技大學護理系副教授兼學生事務長" },
                        { name: "陳昭雄", info: "弘光科技大學食品科技系教授" },
                        { name: "楊昌裕", info: "遠東科技大學休閒與運動管理系副教授兼行政副校長" },
                        { name: "柯慧貞", info: "亞洲大學講座教授兼副校長" },
                        { name: "王淑芳", info: "東吳大學總務長、東吳大學師資培育中心兼任講師、實踐家教育基金會董事" },
                        { name: "莊慶達", info: "國立臺灣海洋大學海洋事務與資源管理研究所名譽教授" },
                        { name: "王信雄", info: "亞東技術學院電機系副教授" },
                        { name: "林賜德", info: "中原大學教授兼人育學院院長" },
                        { name: "張少熙", info: "國立臺灣師範大學運動休閒與餐旅管理研究所教授兼學務長" },
                        { name: "董旭英", info: "國立成功大學教育研究所教授兼所長兼學務長" },
                        { name: "王延年", info: "龍華科技大學學務長" },
                        { name: "王英洲", info: "輔仁大學醫學院臨床心理學系教授兼學務長" },
                        { name: "蘇武昌", info: "中興大學學務長" },
                        { name: "楊瑞蓮", info: "銘傳大學教授兼學務長兼體育室顧問暨軍訓室主任" },
                        { name: "謝登旺", info: "元智大學社會暨政策科學系教授" },
                        { name: "魏裕昌", info: "中國文化大學學務長" },
                        { name: "蔡文彬", info: "遠東科技大學學務長" },
                        { name: "鄭亞薇", info: "中國科技大學副教授兼學務長" },
                        { name: "林俞利", info: "致理科技大學企管系暨服務業經營管理研究所系主任" },
                        { name: "洪錦珠", info: "華夏科技大學通識教育中心副教授兼學務長" },
                        { name: "楊益强", info: "臺北市立松山高級工農職業學校校長" },
                        { name: "顏世慧", info: "樹德科技大學學務長" },
                        { name: "朱朝煌", info: "國立成功大學學務處課外活動指導組組員" },
                        { name: "周雪美", info: "元培醫事科技大學生技製藥系教授" },
                    ]
                },])
        },
        {
            title: "第41屆監事名單",
            content: GroupList([

                {
                    gruop: "常務監事",
                    list: [
                        { name: "黃貴美", info: "世界和平婦女會台灣總會名譽理事長、全球和平聯盟台灣總會常務理事" },
                    ]
                },
                {
                    gruop: "監事",
                    list: [
                        { name: "黃丕陵", info: "前崇右技術學院校長、前國立臺北科技大學副校長" },
                        { name: "何英奇", info: "中國文化大學心輔系教授兼系主任" },
                        { name: "何進財", info: "前教育部訓委會常務委員、前教育部社教司司長" },
                        { name: "史濟鍠", info: "醒吾科技大學兼任副教授" },
                        { name: "林詩輝", info: "醒吾科技大學副教授" },
                        { name: "黃宏斌", info: "國立臺灣大學生物環境系統工程學系教授" },
                        { name: "溫振源", info: "國立臺灣大學名譽教授" },
                        { name: "周漢東", info: "國立臺灣大學僑陸組主任" },
                        { name: "戴麗華", info: "國立臺北大學兼任教授、名譽教授" },
                        { name: "陶長安", info: "醒吾中學人事主任" },
                    ]
                },])
        },
        {
            title: "第40屆理事名單",
            content: GroupList([
                {
                    gruop: "榮譽理事長",
                    list: [
                        { name: "周家華", info: "國立臺北科技大學教授、前醒吾、德霖技術學院校長" },
                    ]
                },
                {
                    gruop: "理事長",
                    list: [
                        { name: "張雪梅", info: "國立臺灣師範大學公民教育與活動領導學系教授" },
                    ]
                },
                {
                    gruop: "副理事長",
                    list: [
                        { name: "童中儀", info: "金甌高級女子中學校長" },
                        { name: "林錦川", info: "東吳大學前副校長" },
                    ]
                },
                {
                    gruop: "常務理事",
                    list: [
                        { name: "柯志堂", info: "德明財經科技大學學務長" },
                        { name: "陳昭雄", info: "弘光科技大學教授" },
                        { name: "林至善", info: "玄奘大學學務長" },
                        { name: "林賜德", info: "中原大學前學務長" },
                        { name: "楊健貴", info: "銘傳大學前學務長/ 現任資訊工程學系教授" },
                        { name: "梁朝雲", info: "前元智大學學務長/ 現任國立臺灣大學教授" },
                        { name: "劉維群", info: "前國立高雄餐旅學院學務長/ 現任進修推廣學院院長" },
                        { name: "鄭武德", info: "前明新科技大學學務長/ 現任明新科技大學進修推廣部主任" },
                    ]
                },
                {
                    gruop: "理事",
                    list: [
                        { name: "柳金財", info: "佛光大學學務長" },
                        { name: "劉杏元", info: "長庚科技大學護理系副教授兼學生事務長" },
                        { name: "陳應琮", info: "前東南工專校長/ 現任東南科技大學教育董事" },
                        { name: "楊昌裕", info: "佛光大學副教授" },
                        { name: "柯慧貞", info: "亞洲大學講座教授兼副校長" },
                        { name: "王淑芳", info: "東吳大學總務長" },
                        { name: "莊慶達", info: "國立臺灣海洋大學海洋事務與資源管理研究所名譽教授" },
                        { name: "王信雄", info: "亞東技術學院前學務長" },
                        { name: "陳光憲", info: "前德明商專校長 現任德明財經科技大學通識中心講座教授" },
                        { name: "周雪美", info: "元培醫事科技大學生物技術科技系教授" },
                        { name: "黃貴美", info: "中國文化大學市政暨環境規劃系教授" },
                        { name: "馮燕", info: "國立臺灣大學前學務長/ 現任行政院政務委員" },
                        { name: "孫台鼎", info: "靜宜大學學務長" },
                        { name: "鄭經偉", info: "國立中興大學前學務長/ 現任生物產業機電工程學系教授" },
                        { name: "楊百川", info: "輔仁大學企業管理學系教授" },
                        { name: "徐畢卿", info: "國立成功大學前學務長/ 現任國立成功大學護理系教授" },
                        { name: "劉若蘭", info: "國立臺灣師範大學公民活動領導學系副教授" },
                        { name: "周東漢", info: "國立臺灣大學僑陸組主任" },
                        { name: "羅運治", info: "淡江大學歷史系教授" },
                        { name: "楊仕裕", info: "輔仁大學助理教授" },
                        { name: "林啟禎", info: "國立成功大學學務長" },
                        { name: "林月雲", info: "國立政治大學前學務長/ 現任國立政治大學企管系教授" },
                        { name: "林詩輝", info: "醒吾科技大學副教授" },
                        { name: "林英俊", info: "醒吾科技大學前學務長" },
                    ]
                },])
        },
        {
            title: "第40屆監事名單",
            content: GroupList([

                {
                    gruop: "監事長",
                    list: [
                        { name: "何進財", info: "前教育部訓委會常務委員、前教育部社教司司長" },
                    ]
                },
                {
                    gruop: "常務監事",
                    list: [
                        { name: "黃丕陵", info: "前崇右技術學院校長、前國立臺北科技大學副校長" },
                        { name: "溫振源", info: "國立臺灣大學前學務長/現任名譽教授" },
                    ]
                },
                {
                    gruop: "監事",
                    list: [
                        { name: "何英奇", info: "中國文化大學心輔系教授兼系主任" },
                        { name: "沈六", info: "國立臺灣師範大學公民教育與活動領導學系兼任教授" },
                        { name: "史濟鍠", info: "醒吾科技大學兼任副教授" },
                        { name: "魏世台", info: "東南科技大學通識教育中心教授兼教學資源中心主任" },
                        { name: "黃宏斌", info: "國立臺灣大學生物環境系統工程學系教授" },
                        { name: "周玲臺", info: "國立政治大學學務長" },
                        { name: "戴麗華", info: "國立臺北大學兼任教授、名譽教授" },
                        { name: "何寄澎", info: "前國立臺灣大學學務長/ 現任考試院委員" },
                    ]
                },])
        },
    ]


    return (<>
        <SubTitle title="理監事會" />
        <br />
        {contents.map(c => (<Accordion disableGutters key={c.title}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon sx={{ fill: "#fff" }} />}
                aria-controls="panel1a-content"
                sx={{ flexDirection: 'row-reverse', backgroundColor: "primary.main", color: "#fff" }}
            >
                <Typography >{c.title}</Typography>
            </AccordionSummary>
            <AccordionDetails >
                {c.content}
            </AccordionDetails>
        </Accordion>))}

    </>)


}


