import { useTheme, Container, Paper, Typography, Stack, Link } from "@mui/material";
import * as React from "react";
import { Trans, useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { scroller } from 'react-scroll'


import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import { SubTitle } from "../../components/Common";

export function Tab1of5() {
    const history = useHistory();
    const theme = useTheme();
    const { t } = useTranslation();


    return (<>
        <SubTitle title="投審稿系統" />
        <br />
        <Typography variant='body1' >
            歡迎關心學生事務工作者，踴躍投稿，如有稿件請投至<Link href='https://www.ipress.tw/J0076' target='_blank'>「投審稿系統」</Link>。
        </Typography>
    </>)

}
