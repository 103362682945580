import * as React from 'react';
import { Backdrop, Box, Container, SxProps, Theme, Typography, useTheme, Stack, ListItemButton, ListItemText, Collapse, List } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { useHistory } from 'react-router-dom';
import { ExpandLess, ExpandMore } from '@mui/icons-material';


export interface SidebarItemchilds {
    nodeId: string;
    title: string;
}

export interface SidebarItem {
    nodeId: string;
    title: string;
    childs?: SidebarItemchilds[];
}

export function PageSidebar(props: { sidebarItem: SidebarItem[]; handleChange: (newValue: string) => void; }) {

    return (
        <>
            <List>
                {props.sidebarItem.map(item => <SideBarButton data={item} key={item.nodeId} handleChange={props.handleChange} />)}
            </List>
        </>)
}


export interface TabPanelProps {
    children?: React.ReactNode;
    index: string;
    value: string;
}

export function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    {children}
                </Box>
            )}
        </div>
    );
}

interface SideBarButtonProps {
    data: SidebarItem;
    handleChange?: (newValue: string) => void;
}

export function SideBarButton(props: SideBarButtonProps) {
    const { data, handleChange } = props;
    const [open, setOpen] = React.useState(false);
    const history = useHistory();

    const handleClick = () => {
        setOpen(!open);
    };

    const handlePageClick = (page: SidebarItem) => {
        handleChange(page.nodeId);
    }

    if (data.childs?.length > 0)
        return (<>
            <ListItemButton onClick={handleClick} sx={{ color: "rgb(84, 108, 153)" }}>
                <ListItemText primary={data.title} />
                {open ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {data.childs?.map((page) => (
                        <ListItemButton key={page.title} sx={{ pl: 4 }} onClick={() => handlePageClick(page)}>
                            <ListItemText primary={page.title} sx={{ color: "rgb(112, 112, 112)" }} />
                        </ListItemButton>
                    ))}
                </List>
            </Collapse>
        </>)
    else
        return (
            <ListItemButton onClick={() => handlePageClick(data)}>
                <ListItemText primary={data.title} sx={{ color: "rgb(84, 108, 153)" }} />
            </ListItemButton>
        )

}