import { useTheme, Box, Container, Paper, Typography, Stack } from "@mui/material";
import * as React from "react";
import { Trans, useTranslation } from "react-i18next";
import { useHistory } from "react-router";

import { SubTitle } from "../../components/Common";

export function Tab1of1() {
    const history = useHistory();
    const theme = useTheme();
    const { t } = useTranslation();

    return (<>
        <SubTitle title="學會沿革" />
        <br />
        <Typography variant='body1' sx={{ "fontFamily": "標楷體", "fontSize": "medium" }}>
            &emsp;&emsp;「中國訓育學會」 於中華民國四十七年九月十日在臺北成立。迄今已超過五十年。民國四十三年底，一部份從事訓導業務的同仁，鑒於大專學校是培植社會國家青年領導人才的場所，關乎國脈興替至鉅，尤其訓導工作與學生關係較多，對學生影響亦大。因之學校訓導制度與訓導工作方法、訓導人員進修福利等等，亟待加強，於是各校訓導工作人員包括訓導長(主任)、課外活動主任(組長)等，以「中華民國大專學校訓導人員聯誼會」方式，每年分別在台灣大學、師範大學及民眾團體活動中心舉行年會，先後四次，就訓育制度、訓導方法及工作人員進修等議題，進行討論，教育部、教育廳均曾派員列席指導，每次會議均有具體意見轉呈教育當局，希望能採擇施行。於民國四十六年七月十三日第五次會議時，咸認應積極推展業務，強化工作內容，一致決議將聯誼會改名為「中國大專學校訓導研究會」，俾以實際研究所得，提供政府參考，即席推定七位同仁負責籌備，計台大黃亦晨、周廷洛，師大曹世昌，政大楊公策，工專湯青雲，法商徐淑貞，東吳胡定國，淡江蕭傑興等人；教育部訓委會亦給予指導協助，各大專學校訓導長更熱心關切，全力支持，使籌備工作得以順利進行。
        </Typography>
        <br />
        <Typography variant='body1' sx={{ "fontFamily": "標楷體", "fontSize": "medium" }}>
            &emsp;&emsp;自民國四十六年十二月廿七日至四十七年七月卅一日歷時半載有餘，先後舉行籌備會議五次，其間關於邀請發起人，徵募會員，向主管機關接洽各種事務工作，均經負責籌備諸同仁互助合作，戮力完成。民國四十七年六月廿日奉內政部台(47)內社字第一八八三九號文核准成立，定名為「中國訓育學會」，隨即著手籌備成立大會，推黃亦晨先生任總幹事，周廷洛先生負責總務組，曹世昌先生負責議事組，湯青雲先生、楊公策先生負責秘書組，徐淑貞先生負責招待組，並由成功大學丁訓導長作韶以召集人名義向有關機關申請補助大會經費。經分頭接洽，教育部、中央黨部、中國青年反共救國團及台大、政大、師大、工專、成大、農院、海專、法商等院校均有補助，共計新幣八千元，由此足證政府及各界對於學校訓導工作之重視，誠堪告慰，實予訓導人員以莫大之鼓勵。當時為擴大社會影響使社會人士獲得廣大重視起見，曾計劃在各報出版特刊，並請諸位先進撰賜宏文，惟經多方接洽結果，因受條件限制，未能實現，除一部份稿送報刊載外，其餘刊載於成立大會之特刊中，以供參考。至於經費部份於理監事會成立後，悉數移交專人接管。
        </Typography>
        <Typography variant='body1' sx={{ "fontFamily": "標楷體", "fontSize": "medium" }}>
            &emsp;&emsp;民國四十七年九月十日，在臺北市實踐堂正式舉行本會成立大會，出席一五六人，由丁作韶先生擔任主席，請楊希震先生專題演講，講題為「中國訓育之基本精神」，並通過健全訓導制度，及出版「訓育研究」等重要提案，會中選出理事傅啟學、丁作韶、葉守乾、朱施民、黃亦晨、周廷洛、鍾同禮、何龍、王震寰、張樂陶、曹世昌、賈智林、湯青雲、開濟、楊寶乾等十五人。監事楊希震、查良釗、張志韓、鄒文耀、孫嘉時等五人。組成第一屆理監事會，恭推丁作韶先生擔任 首任理事長。自第二屆起理事長分別為傅啟學先生、丁作韶先生、劉發宣先生、楊希震先生、洪同先生、程運先生、楊極東先生、陶佩潛先生、林恩顯先生、劉瑞生先生、黎建球先生、周家華先生等。
        </Typography>
        <br />
        <Typography variant='body1' sx={{ "fontFamily": "標楷體", "fontSize": "medium" }}>
            &emsp;&emsp;其間理監事人數，亦因大專院校增加而有所變動，最初理事十五人、監事五人、自民國五十五年第六屆起，理事增為廿一人、監事七人，民國六十年第廿二屆起理事增為廿三人、監事九人，至民國七十九年第廿九屆起，依法定全國性人民團體理監事名額改為理事卅五人、監事十一人。
        </Typography>
        <br />
        <Typography variant='h5' sx={{ "fontFamily": "標楷體", "fontSize": "medium", fontWeight: "bold" }}>
            第四十四屆理事長 林至善先生
        </Typography>
        <br />
    </>)

}
