import { useTheme, Box, Container, Paper, Typography, Stack } from "@mui/material";
import * as React from "react";
import { Trans, useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { SubTitle } from "../../components/Common";

export function Tab1of5() {
    const history = useHistory();
    const theme = useTheme();
    const { t } = useTranslation();


    return (<>
        <SubTitle title="會徽" />
        <br />
        <Box sx={{ width: "max-content", margin: '0 auto' }}>
            <img src='./images/logo2.png' width='200px' />
        </Box>
        <br />

        <Typography variant='subtitle1' fontSize="1.15rem" textAlign="center">展開的書本代表課堂內的學習。</Typography>
        <Typography variant='subtitle1' fontSize="1.15rem" textAlign="center">左右兩邊人形代表課堂外學生事務多元學習與成長。</Typography>
        <Typography variant='subtitle1' fontSize="1.15rem" textAlign="center">臺灣圖形代表我國高等教育與學生事務的發展。</Typography>
        <Typography variant='subtitle1' fontSize="1.15rem" textAlign="center">充滿速度迴旋的線條，表示促進課堂內與課堂外的互動與合作。</Typography>
        <Typography variant='subtitle1' fontSize="1.15rem" textAlign="center">綠色指永續教育成長。</Typography>
        <Typography variant='subtitle1' fontSize="1.15rem" textAlign="center">橙色指朝氣活力。</Typography>
        <Typography variant='subtitle1' fontSize="1.15rem" textAlign="center">藍色指專業優質。</Typography>
        <Typography variant='subtitle1' fontSize="1.15rem" textAlign="center">銀灰色指現在效率。</Typography>
    </>)

}
