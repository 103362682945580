import { configureStore } from '@reduxjs/toolkit';
import { rootReducer } from "./rootReducer";
import { useDispatch, useSelector } from 'react-redux';
import { userApi } from '../components/services/user';
import { loginApi } from '../components/services/login';
import { systemApi } from '../components/services/system';
import { fileApi } from '../components/services/file';
import { eventApi } from '../components/services/event';
import { newsApi } from '../components/services/news';
import { publicationApi } from '../components/services/publication';

export const store = configureStore({
    reducer: {
        rootReducer: rootReducer,
        [eventApi.reducerPath]: eventApi.reducer,
        [fileApi.reducerPath]: fileApi.reducer,
        [loginApi.reducerPath]: loginApi.reducer,
        [systemApi.reducerPath]: systemApi.reducer,
        [userApi.reducerPath]: userApi.reducer,
        [newsApi.reducerPath]: newsApi.reducer,
        [publicationApi.reducerPath]: publicationApi.reducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false,
    })
        .concat(eventApi.middleware)
        .concat(fileApi.middleware)
        .concat(loginApi.middleware)
        .concat(systemApi.middleware)
        .concat(userApi.middleware)
        .concat(newsApi.middleware)
        .concat(publicationApi.middleware)

});

export type RootState = ReturnType<typeof store.getState>
export type DispatchType = typeof store.dispatch

//�`��hook
export const useUser = () => useSelector((state: RootState) => state.rootReducer.user);
export const useModalControl = () => useSelector((state: RootState) => state.rootReducer.modalControl);
export const useScrollTrigger = () => useSelector((state: RootState) => state.rootReducer.scrollTrigger);

export const useDispatchWithType = () => useDispatch<DispatchType>();
export const useSelectedYear = () => useSelector((state: RootState) => state.rootReducer.selectedYear);
export const useSelectedPage = () => useSelector((state: RootState) => state.rootReducer.selectedPage);
