import { Avatar, Backdrop, Box, CircularProgress, LinearProgress, Stack } from "@mui/material";
import { DataGrid, GridEnrichedColDef, GridRenderCellParams, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarExport, GridToolbarFilterButton, GridValueGetterParams } from "@mui/x-data-grid";
import format from "date-fns/format";
import * as React from "react";
import { AddUserModel } from "../../components/services/user";
import { ResultType } from "../../components/services/utils";
import { SubTitle } from "../../components/Common";
import { zhTWGrid } from "../data-grid-locale";
import { AddButton, CyanBackStageButton, GreenBackStageButton, GreyBackStageButton, RedBackStageButton } from "../components";
import { UserData, Publication, PublicationType } from "../../components/class";
import { useLazyGetPublicationDetailQuery } from "../../components/services/publication";

interface PublicationListProps {
    onAdd: () => void;
    onEdit: (publicationId: string) => void;
    onView: (publicationId: string) => void;
    onDelete: (publicationId: string) => void;
    publicationList: ResultType<Publication[]>;
    isPublicationListLoading: boolean;
    isFetching: boolean;
    type: PublicationType;
}

export function PublicationList(props: PublicationListProps) {
    const { onAdd, onEdit, onView, onDelete, publicationList, isPublicationListLoading, isFetching, type } = props;
    React.useEffect(() => {

    }, []);
    return (<>
        <Box display='flex'>
            <SubTitle title={type + "管理"} />
        </Box>
        <AddButton onClick={onAdd} sx={{ my: 1.5 }}>
            新增刊物
        </AddButton>
        <DataList
            dataList={publicationList?.data.map(item => ({ ...item, id: item.publicationId })) ?? []}
            handleEditPublication={onEdit}
            handleViewPublication={onView}
            handleDeletePublication={onDelete}
            loading={isPublicationListLoading}
            isFetching={isFetching}
        />
    </>);
}

interface GridDataRow extends Publication {
    id: string;
}

interface DataListProps {
    dataList: GridDataRow[];
    handleEditPublication: (publicationId: string) => void;
    handleViewPublication: (publicationId: string) => void;
    handleDeletePublication: (publicationId: string) => void;
    loading?: boolean;
    isFetching: boolean;
}
function DataList(props: DataListProps) {
    const { dataList, loading, handleEditPublication, handleViewPublication, handleDeletePublication, isFetching } = props;

    const [getPublicationDetail, { isLoading }] = useLazyGetPublicationDetailQuery()


    const columns: GridEnrichedColDef<GridDataRow>[] = [
        {
            field: 'imageUrl',
            headerName: '封面',
            width: 100,
            sortable: false,
            renderCell: ({ value: imageUrl }: GridRenderCellParams<string, GridDataRow>) => (
                <>
                    <img src={imageUrl} style={{ maxWidth: '100%', height: '80px' }} />
                </>
            ),
        },
        {
            field: 'title',
            headerName: '卷別',
            width: 200,
        },
        {
            field: 'subtitle',
            headerName: '季刊主題',
            width: 200,
        },

        {
            field: 'publicationId',
            headerName: 'pdf',
            width: 120,
            renderCell: ({ value: publicationId }: GridRenderCellParams<string, GridDataRow>) => (
                <>
                    <CyanBackStageButton onClick={() => {
                        console.log('讀取PDF')
                        getPublicationDetail(publicationId).unwrap().then(res => res.isSuccess ? window.open(res.data.pdfUrl, '_blank') : '')
                    }}>
                        開啟PDF
                    </CyanBackStageButton>
                </>
            ),
        },

        {
            field: 'actions',
            headerName: '操作',
            width: 270,
            renderCell: ({ row: { publicationId } }: GridRenderCellParams<GridDataRow>) => (
                <Stack direction='row' spacing={1}>
                    <GreenBackStageButton onClick={() => handleEditPublication(publicationId)}>
                        編輯資料
                    </GreenBackStageButton>
                    <RedBackStageButton variant='contained' onClick={() => handleDeletePublication(publicationId)} >
                        刪除
                    </RedBackStageButton>
                </Stack>
            )
        },
    ];

    return (<>
        <DataGrid rows={dataList} columns={columns}
            pageSize={10}
            autoPageSize
            autoHeight
            rowHeight={100}
            disableColumnMenu
            disableColumnSelector
            components={{
                //  Toolbar: CustomToolbar,
                LoadingOverlay: LinearProgress
            }}
            localeText={zhTWGrid}
            loading={loading || isFetching}
        />
        <Backdrop open={isLoading} sx={{ color: "#fff", zIndex: '99' }}>
            <CircularProgress color="inherit" />
        </Backdrop>
    </>
    )
}