import { useTheme, Box, Container, Paper, Typography, Stack } from "@mui/material";
import * as React from "react";
import { Trans, useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { scroller } from 'react-scroll'


import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import { SubTitle } from "../../components/Common";

export function Tab1of1() {
    const history = useHistory();
    const theme = useTheme();
    const { t } = useTranslation();

    return (<>
        <SubTitle title="歷史沿革" />
        <br />
        <Typography variant='body1' >
            &emsp;&emsp;「中國訓育學會」成立於民國47年，以「強化訓育研究」、「促進訓育經驗分享」和「增進校園安定與學生學習」為使命。自民國48年1月創刊發行《訓育研究》刊物，隨著社會脈絡的轉變更替，本會改名為「中華學生事務學會」，《訓育研究》亦於民國91年更名為《學生事務－理論與實務》。       </Typography>
        <br />
        <Typography variant='body1' >
            &emsp;&emsp;自民國四十六年十二月廿七日至四十七年七月卅一日歷時半載有餘，先後舉行籌備會議五次，其間關於邀請發起人，徵募會員，向主管機關接洽各種事務工作，均經負責籌備諸同仁互助合作，戮力完成。民國四十七年六月廿日奉內政部台(47)內社字第一八八三九號文核准成立，定名為「中國訓育學會」，隨即著手籌備成立大會，推黃亦晨先生任總幹事，周廷洛先生負責總務組，曹世昌先生負責議事組，湯青雲先生、楊公策先生負責秘書組，徐淑貞先生負責招待組，並由成功大學丁訓導長作韶以召集人名義向有關機關申請補助大會經費。經分頭接洽，教育部、中央黨部、中國青年反共救國團及台大、政大、師大、工專、成大、農院、海專、法商等院校均有補助，共計新幣八千元，由此足證政府及各界對於學校訓導工作之重視，誠堪告慰，實予訓導人員以莫大之鼓勵。當時為擴大社會影響使社會人士獲得廣大重視起見，曾計劃在各報出版特刊，並請諸位先進撰賜宏文，惟經多方接洽結果，因受條件限制，未能實現，除一部份稿送報刊載外，其餘刊載於成立大會之特刊中，以供參考。至於經費部份於理監事會成立後，悉數移交專人接管。
        </Typography>
        <br />
        <Typography variant='body1'  >
            &emsp;&emsp;103年因為教育部學生事務與特殊教育司的補助請託，本刊將充實內涵並擴大服務讀者群，再度更名《學生事務與輔導》以強化高中職與大學的夥伴關係，並使學務與輔導人員能有更緊密的合作關係。      </Typography>
        <br />
        <Typography variant='body1'  >
            &emsp;&emsp;本會刊物創刊至今已出版56卷，60餘年來持續發行，成為國內歷史最為悠久的學會刊物之一，獲得各界好評與信賴，更成為學務人員發表的重要園地，對於傳遞學務工作的理論、新知，擴展學務人員的視野，扮演重要而關鍵的角色。
        </Typography>
        <br />
        <Typography variant='body1'  >
            &emsp;&emsp;為了不斷提升本刊品質，今後將持續以訂定重要議題與嚴謹的審稿制度，並廣泛徵稿，來達成「中華學生事務學會」出版本刊的目的。因此懇請您，以及所有關心學務工作、重視學生學習發展的教育界夥伴，給予本刊更多的支持協助。 </Typography>
        <br />
    </>)

}
