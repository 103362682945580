import { useTheme, Box, Container, Paper, Typography, Stack, Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import * as React from "react";
import { Trans, useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { SubTitle } from "../../components/Common";

export function Tab1of6() {
    const history = useHistory();
    const theme = useTheme();
    const { t } = useTranslation();
    const contents = [
        {
            title: "101年度學生事務專題論文獲獎名單",
            content: <Box paddingTop="10px">
                <Typography variant='subtitle1' fontWeight="bold" marginBottom="5px">
                    ◎蔡志賢　博士論文
                </Typography>
                <Typography variant='body1' marginBottom="10px">
                    《高等教育課外學習核心能力及其評量指標建構研究》
                </Typography>
                <Typography variant='subtitle1' fontWeight="bold" marginBottom="5px">
                    ◎姜怡君　碩士論文
                </Typography>
                <Typography variant='body1' marginBottom="10px">
                    《校園經驗、學校認同與續讀意願之關係－以臺北地區私立大學大一學生為例》
                </Typography>
                <Typography variant='subtitle1' fontWeight="bold" marginBottom="5px">
                    ◎朱惠敏　碩士論文
                </Typography>
                <Typography variant='body1' marginBottom="10px">
                    《北區大學社團負責人角色壓力及其因應策略研究》
                </Typography>
            </Box>
        },
        {
            title: "100年度學生事務專題論文獲獎名單",
            content: <Box paddingTop="10px">
                <Typography variant='subtitle1' fontWeight="bold" marginBottom="5px">
                    ◎朱朝煌　博士論文
                </Typography>
                <Typography variant='body1' marginBottom="10px">
                    《大學生領導力影響因素之研究》
                </Typography>
                <Typography variant='subtitle1' fontWeight="bold" marginBottom="5px">
                    ◎張孟華　碩士論文
                </Typography>
                <Typography variant='body1' marginBottom="10px">
                    《大學學生宿舍自治組織幹部暨同儕輔導員角色認同與實踐之研究》
                </Typography>
                <Typography variant='subtitle1' fontWeight="bold" marginBottom="5px">
                    ◎李育枝　碩士論文
                </Typography>
                <Typography variant='body1' marginBottom="10px">
                    《大學校院學生事務人員組織學習與工作效能之研究》
                </Typography>
            </Box>
        },
        {
            title: "99年度學生事務專題論文獲獎名單",
            content: <Box paddingTop="10px">
                <Typography variant='subtitle1' fontWeight="bold" marginBottom="5px">
                    ◎王介汶　碩士論文
                </Typography>
                <Typography variant='body1' marginBottom="10px">
                    《大學學生宿舍內人際問題及處理方式研究-以台北地區大學為例》
                </Typography>
                <Typography variant='subtitle1' fontWeight="bold" marginBottom="5px">
                    ◎李育齊　碩士論文
                </Typography>
                <Typography variant='body1' marginBottom="10px">
                    《大學校院學生事務中階主管經營能力與專業發展需求之研究-以北區大學校院為例》
                </Typography>
            </Box>
        }]

    return (<>
        <SubTitle title="榮譽" />
        <br />
        {contents.map(c => (<Accordion disableGutters key={c.title}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon sx={{ fill: "#fff" }} />}
                aria-controls="panel1a-content"
                sx={{ flexDirection: 'row-reverse', backgroundColor: "primary.main", color: "#fff" }}
            >
                <Typography >{c.title}</Typography>
            </AccordionSummary>
            <AccordionDetails >
                {c.content}
            </AccordionDetails>
        </Accordion>))}

    </>)

}
