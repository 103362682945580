import { useTheme, Box, Container, Paper, Typography, Stack, List, ListItemButton, ListItemText, Collapse } from "@mui/material";
import * as React from "react";
import { Trans, useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router";
import { scroller } from 'react-scroll'
import { PageTitle, SubTitle } from "../components/Common";
import { Tab1of1 } from './tabs/Tab1of1';
import { Tab1of2 } from './tabs/Tab1of2';
import { Tab1of3 } from './tabs/Tab1of3';
import { Tab1of4 } from './tabs/Tab1of4';
import { Tab1of5 } from './tabs/Tab1of5';
import { Tab1of6 } from './tabs/Tab1of6';
import { Tab2 } from './tabs/Tab2';
import { Tab3 } from './tabs/Tab3';
import { Tab4 } from './tabs/Tab4';
import { Tab5 } from './tabs/Tab5';
import { PageSidebar, SidebarItem } from "components/PageSidebar";
import { ExpandLess, ExpandMore } from "@mui/icons-material";

export function About() {
    const history = useHistory();
    const theme = useTheme();
    const { t } = useTranslation();
    const [nodeId, setNodeId] = React.useState<string>("1-1")

    const sidebarItem: SidebarItem[] = [{
        nodeId: "1",
        title: "學會簡介",
        childs: [{
            nodeId: "1-1",
            title: "歷史沿革"
        },
        /*     {
             nodeId: "1-2",
             title: "宗旨"
             },*/
        {
            nodeId: "1-3",
            title: "章程"
        }, {
            nodeId: "1-4",
            title: "組織"
        }, {
            nodeId: "1-5",
            title: "會徽"
        }, {
            nodeId: "1-6",
            title: "榮譽"
        },
        ]
    }, {
        nodeId: "2",
        title: "理事長的話",
    }, {
        nodeId: "3",
        title: "理監事會",
    }, {
        nodeId: "4",
        title: "秘書處",
    }, {
        nodeId: "5",
        title: "會務運作",
    },
    ]

    /*const [sideBarList, setSideBarList] = React.useState<SideBarTitle[]>([]);
    const [pageView, setPageView] = React.useState<PageView>(viewItems);

    React.useLayoutEffect(() => {
        console.log(sideBarItems);
        setSideBarList([...sideBarItems]);
        setPageView(viewItems);
        console.log(sideBarList);
        console.log(pageView);
    }, []);*/
    const location = useLocation();
    const query = new URLSearchParams(location.search);

    // 使用搜索參數
    const hash = query.get('hash');
    React.useEffect(() => {
        if (hash) setNodeId(hash)
    }, [hash])
    const handleSelect = (nodeId: string) => {
        if (nodeId != '1') {
            setNodeId(nodeId)
            history.push("About?hash=" + nodeId)
        }
    };

    return (<>
        <PageTitle title={sidebarItem.find(e => e.nodeId == nodeId || e.childs?.find(i => i.nodeId == nodeId)).title} subtitle="認識學會" />

        <Container sx={{ marginTop: "30px" }}>
            <Stack
                direction={{ xs: "column", md: "row" }}
            >
                <Paper sx={{ width: 250, padding: "50px 20px", height: "max-content" }}>
                    <PageSidebar sidebarItem={sidebarItem} handleChange={handleSelect} />
                </Paper>
                {/*
        <TabsBoardPanel
            allowSideBarList={sideBarList}
            pageView={pageView}
        />*/ }
                <Box sx={{ width: { sm: "100%", md: "calc(100% - 200px)" }, }}>
                    <Tabs nodeId={nodeId} />
                </Box>
            </Stack>
        </Container>
    </>)
}

function Tabs(props: { nodeId: string }) {
    const { nodeId } = props;
    return (
        <Box sx={{ width: '100%' }}>
            <TabPanel value={nodeId} index={"1-1"}>
                <Tab1of1 />
            </TabPanel>
            {/*<TabPanel value={nodeId} index={"1-2"}>
                <Tab1of2 />
            </TabPanel>*/}
            <TabPanel value={nodeId} index={"1-3"}>
                <Tab1of3 />
            </TabPanel>
            <TabPanel value={nodeId} index={"1-4"}>
                <Tab1of4 />
            </TabPanel>
            <TabPanel value={nodeId} index={"1-5"}>
                <Tab1of5 />
            </TabPanel>
            <TabPanel value={nodeId} index={"1-6"}>
                <Tab1of6 />
            </TabPanel>
            <TabPanel value={nodeId} index={"2"}>
                <Tab2 />
            </TabPanel>
            <TabPanel value={nodeId} index={"3"}>
                <Tab3 />
            </TabPanel>
            <TabPanel value={nodeId} index={"4"}>
                <Tab4 />
            </TabPanel>
            <TabPanel value={nodeId} index={"5"}>
                <Tab5 />
            </TabPanel>
        </Box>
    );
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: string;
    value: string;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}