import { useTheme, Box, Container, Paper, Typography, Stack } from "@mui/material";
import * as React from "react";
import { Trans, useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router";
import { scroller } from 'react-scroll'

import { PageTitle, SubTitle } from "components/Common";
import { Tab1of1 } from './tabs/Tab1of1';
import { Tab1of2 } from './tabs/Tab1of2';
import { Tab1of3 } from './tabs/Tab1of3';
import { Tab1of4 } from './tabs/Tab1of4';
import { Tab1of5 } from './tabs/Tab1of5';
import { Tab2 } from './tabs/Tab2';
import { Tab3 } from './tabs/Tab3';
import { PageSidebar, SidebarItem, TabPanel } from "../components/PageSidebar";
import { PublicationContent } from "./tabs/PublicationContent";
import { setSelectedPage, setSelectedYear } from "../store/rootReducer";
import { useDispatchWithType } from "../store";
import { Resource } from "../Event/tabs/Resource";

export function Publication() {
    const history = useHistory();
    const theme = useTheme();
    const { t } = useTranslation();
    const [nodeId, setNodeId] = React.useState<string>("1-1")
    const location = useLocation();
    const type = new URLSearchParams(location.search).get('type')
    const dispatch = useDispatchWithType();

    React.useEffect(() => {
        if (type == '研討會簡報') setNodeId('2')
        if (type == '學生事務與輔導季刊') setNodeId('3')
    }, [type])

    const sidebarItem: SidebarItem[] = [{
        nodeId: "1",
        title: "關於本刊",
        childs: [{
            nodeId: "1-1",
            title: "歷史沿革"
        }, {
            nodeId: "1-2",
            title: "編輯委員"
        }, {
            nodeId: "1-3",
            title: "徵稿辦法"
        }, {
            nodeId: "1-4",
            title: "訂購辦法"
        }, {
            nodeId: "1-5",
            title: "投審稿系統"
        },
        ]
    },
    {
        nodeId: "2",
        title: "研討會簡報",
    },
    {
        nodeId: "3",
        title: "學生事務與輔導季刊",
    },
    {
        nodeId: "4",
        title: "學習資源",
    },
    ]

    const handleSelect = (nodeId: string) => {
        if (nodeId != '1') {
            setNodeId(nodeId)
            if (type) {
                history.push('/Publication')
            }
            dispatch(setSelectedYear(''));
            dispatch(setSelectedPage(1));
        }
    };

    return (<>
        <PageTitle title={sidebarItem.find(e => e.nodeId == nodeId || e.childs?.find(i => i.nodeId == nodeId)).title} subtitle="學會刊物" />
        <Container sx={{ marginTop: "30px" }}>
            <Stack
                direction={{ xs: "column", md: "row" }} spacing={2}
            >
                <Paper sx={{ width: 250, padding: "50px 20px", height: "max-content" }}>
                    <PageSidebar sidebarItem={sidebarItem} handleChange={handleSelect} />
                </Paper>
                <Box sx={{ width: { sm: "100%", md: "calc(100% - 200px)" }, }}>
                    <Tabs nodeId={nodeId} />
                </Box>
            </Stack>
        </Container>
    </>)
}

function Tabs(props: { nodeId: string }) {
    const { nodeId } = props;
    const location = useLocation();
    const type = new URLSearchParams(location.search).get('type')
    const publicationId = new URLSearchParams(location.search).get('publicationId')
    return (
        <Box sx={{ width: '100%' }}>
            <TabPanel value={nodeId} index={"1-1"}>
                <Tab1of1 />
            </TabPanel>
            <TabPanel value={nodeId} index={"1-2"}>
                <Tab1of2 />
            </TabPanel>
            <TabPanel value={nodeId} index={"1-3"}>
                <Tab1of3 />
            </TabPanel>
            <TabPanel value={nodeId} index={"1-4"}>
                <Tab1of4 />
            </TabPanel>
            <TabPanel value={nodeId} index={"1-5"}>
                <Tab1of5 />
            </TabPanel>
            <TabPanel value={nodeId} index={"2"}>
                {type == "研討會簡報" && publicationId ? <PublicationContent type={type} publicationId={publicationId} /> : <Tab2 />}
            </TabPanel>
            <TabPanel value={nodeId} index={"3"}>
                {type == "學生事務與輔導季刊" && publicationId ? <PublicationContent type={type} publicationId={publicationId} /> : <Tab3 />}
            </TabPanel>
            <TabPanel value={nodeId} index={"4"}>
                <Resource />
            </TabPanel>
        </Box>
    );
}



