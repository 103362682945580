import * as React from "react";
import { AppBar, Avatar, Box, Button, Drawer, IconButton, IconButtonProps, List, ListItem, ListItemButton, ListItemText, Stack, styled, SvgIcon, Theme, Toolbar, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useUser } from "../../store";
import { useHistory, useLocation } from "react-router-dom";
import { UserRole } from "../class";
import { scroller } from "react-scroll";
import { isMenuItem, NavBarItem, NavBarLinkItem } from "./NavBar";
import { useTranslation } from "react-i18next";
import { logEvent } from "../Analytics";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faUserCircle, faTimes } from "@fortawesome/free-solid-svg-icons";
import LanguageToggle from "../LanguageToggle";
import { PopOutButton } from "./NavBarButton";
import { AccountCircle, Close, Menu } from "@mui/icons-material";

const BG = "./images/fin_Background.png";
const drawerWidth: number = 480;

interface NavSideBarProps {
    navItems: NavBarItem[];
}
export function NavSideBar(props: NavSideBarProps) {
    const { navItems } = props;
    const user = useUser();
    const history = useHistory();
    const pathName = useLocation().pathname;

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const { t, i18n } = useTranslation();

    const [isOpen, setIsOpen] = React.useState(false);
    const toggle = () => setIsOpen(!isOpen);
    const toggleClose = () => setIsOpen(false);


    //偵測是否為首頁
    const [isHomePage, setIsHomePage] = React.useState<boolean>(false);
    React.useEffect(() => {
        let matches = [/\/(\?.+)?$/];
        let path = pathName.toLowerCase();
        if (matches.some(match => path.match(match))) {
            setIsHomePage(true);
        }
    }, [pathName]);

    const handleClickItem = (item: NavBarLinkItem, event) => {
        event.preventDefault();
        if (item.link) {
            logEvent(`AppBar`, `Link`, `${item.link}`);
            history.push(item.link);
            // 首頁觸發scroll
            /*
            if (isHomePage) {
                scroller.scrollTo(item.link.split('#')[1], null);
            }
            else {
                history.push(item.link);
            }
            */
        }
        if (item.onClick) {
            item.onClick(event);
        }
        toggleClose();
    };

    const SideBarButton = (props: IconButtonProps) => {
        return (
            <Box borderRadius="50%" bgcolor="background.default" boxShadow={(theme) => theme.shadows[3]}>
                <IconButton {...props} />
            </Box >
        )
    };

    if (!isMobile) return null;
    return (<>
        <Box display="flex" position="fixed" top={8} right={12} zIndex={theme.zIndex.appBar}>
            <SideBarButton size={isMobile ? "small" : "medium"} onClick={toggle}>
                {isMobile ?
                    <Menu /> :
                    <AccountCircle />}
            </SideBarButton>
        </Box>
        <Drawer anchor="right" open={isOpen} onClose={toggleClose}>
            <Stack width={isMobile ? "100vw" : drawerWidth} height="100%">
                <Box display="flex" justifyContent="flex-end" px={1.5} py={1} sx={{ background: `url('${BG}')`, backgroundSize: "1200px auto", backgroundPosition: "600px 0" }}>
                    <SideBarButton size={isMobile ? "small" : "medium"} onClick={toggle}>
                        <Close />
                    </SideBarButton>
                </Box>
                {/*<Box flex="1 1">

                </Box>*/}
                <Stack bgcolor="primary.main" flex="1 1">
                    {isMobile && <Stack flex="1 1" justifyContent="center" alignItems="center" spacing={2}>
                        {navItems.map((item) => {
                            if (isMenuItem(item)) return (
                                <PopOutButton
                                    key={item.name} item={item}
                                    onClickItem={handleClickItem}
                                />
                            );
                            else return (
                                <Button key={item.name} href={item.link} disabled={item.disabled} onClick={(event) => handleClickItem(item, event)} sx={{ color: "white", fontSize: "2rem", "&:hover": { color: "grey.200" } }}>
                                    {item.t ? t(item.name) : (i18n.language === "ch" ? item.name : item.enName)}
                                </Button>
                            );
                        })}
                    </Stack>}
                    {/* <Box display="flex" justifyContent="center" alignItems="center" height={isMobile ? "50px" : "72px"}>
                        <LanguageToggle width={128} size={isMobile ? "small" : "medium"} />
                    </Box>*/}
                </Stack>
            </Stack>
        </Drawer>
    </>);
}
